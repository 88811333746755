import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import Modal from "react-modal";
import moment from "moment";
import { motion } from "framer-motion";
import axios from "axios";
import { useUserContext } from "../contexts/UserContext";
import {
  SingleEliminationBracket,
  Match,
  SVGViewer,
  DoubleEliminationBracket,
} from "@g-loot/react-tournament-brackets";
import SimpleReactValidator from "simple-react-validator";
import "react-quill/dist/quill.core.css";
import ReactPlayer from "react-player/lazy";

const TournamentDetail = () => {
  const { id } = useParams();
  const { user, userAction } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [tournament, setTournament] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeLeauge, setActiveLeauge] = useState("");
  const [uploadedLogo, setUploadedLogo] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [uploadedLogoUrl, setUploadedLogoUrl] = useState("");
  const [, forceUpdate] = useState();

  const [bracketWidth, setBracketWidth] = useState(null);

  const [fullTeamName, setFullTeamName] = useState("");
  const [shortTeamName, setShortTeamName] = useState("");
  const [captainPhone, setCaptainPhone] = useState("");
  const [captainEmail, setCaptainEmail] = useState("");
  const [contact2Phone, setContact2Phone] = useState("");
  const [contact2Email, setContact2Email] = useState("");
  const [player1Username, setPlayer1Username] = useState("");
  const [player2Username, setPlayer2Username] = useState("");
  const [player3Username, setPlayer3Username] = useState("");
  const [player4Username, setPlayer4Username] = useState("");
  const [player5Username, setPlayer5Username] = useState("");
  const [player6Username, setPlayer6Username] = useState("");
  const [player7Username, setPlayer7Username] = useState("");
  const [player1NickName, setPlayer1NickName] = useState("");
  const [player2NickName, setPlayer2NickName] = useState("");
  const [player3NickName, setPlayer3NickName] = useState("");
  const [player4NickName, setPlayer4NickName] = useState("");
  const [player5NickName, setPlayer5NickName] = useState("");
  const [player6NickName, setPlayer6NickName] = useState("");
  const [player7NickName, setPlayer7NickName] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  const modalStyles = {
    content: {
      padding: "50px 40px",
      top: "10%",
      left: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      backgroundColor: "#1f2029",
      border: 0,
      width: "50%",
      height: "75%",
      borderRadius: 15,
      boxShadow:
        "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    },
    overlay: {
      zIndex: 9999,
      backgroundColor: "rgb(0,0,0,0.5)",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getTournament();
    setBracketWidth(document.getElementById("bracket-section").offsetWidth);
  }, [isLoading]);

  useEffect(() => {
    if (user) {
      getApplicantInfo();
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (!uploadedLogo) {
      setPreviewLogo(undefined);
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(uploadedLogo);
    setPreviewLogo(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [uploadedLogo]);

  const groupByKey = (array, key) => {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  };

  const handleUploadLogo = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPreviewLogo(undefined);
      return;
    }

    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/image-upload`,
        formData,
        {
          headers,
        }
      );
      setUploadedLogoUrl(response.data.data);
    } catch (error) {
      console.log(error);
    }

    setUploadedLogo(e.target.files[0]);
  };

  const getTournament = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/tournaments/${id}`,
        { headers }
      );

      const data = response.data.data;
      for (let i = 0; i < data.leauges.length; i++) {
        if (data.leauges[i].single_elimination_matches.length) {
          for (
            let j = 0;
            j < data.leauges[i].single_elimination_matches.length;
            j++
          ) {
            data.leauges[i].single_elimination_matches[j].tournamentRoundText =
              data.leauges[i].single_elimination_matches[j].name;

            data.leauges[i].single_elimination_matches[j].id =
              data.leauges[i].single_elimination_matches[j].match_id;

            data.leauges[i].single_elimination_matches[j].nextMatchId =
              data.leauges[i].single_elimination_matches[j].next_match_id;

            data.leauges[i].single_elimination_matches[j].state =
              data.leauges[i].single_elimination_matches[j].status;

            data.leauges[i].single_elimination_matches[j].participants =
              data.leauges[i].single_elimination_matches[j].teams;

            for (
              let k = 0;
              k <
              data.leauges[i].single_elimination_matches[j].participants.length;
              k++
            ) {
              data.leauges[i].single_elimination_matches[j].participants[
                k
              ].resultText =
                data.leauges[i].single_elimination_matches[j].participants[
                  k
                ].result;

              data.leauges[i].single_elimination_matches[j].participants[
                k
              ].isWinner =
                data.leauges[i].single_elimination_matches[j].participants[
                  k
                ].is_winner;

              data.leauges[i].single_elimination_matches[j].participants[
                k
              ].name =
                data.leauges[i].single_elimination_matches[j].participants[
                  k
                ].team_name;
            }
          }
        }

        if (data.leauges[i].double_elimination_matches.length) {
          for (
            let j = 0;
            j < data.leauges[i].double_elimination_matches.length;
            j++
          ) {
            data.leauges[i].double_elimination_matches[j].id =
              data.leauges[i].double_elimination_matches[j].match_id;

            data.leauges[i].double_elimination_matches[j].nextMatchId =
              data.leauges[i].double_elimination_matches[j].next_match_id;

            data.leauges[i].double_elimination_matches[j].nextLooserMatchId =
              data.leauges[i].double_elimination_matches[
                j
              ].next_looser_match_id;

            data.leauges[i].double_elimination_matches[j].stage =
              data.leauges[i].double_elimination_matches[j].state;

            data.leauges[i].double_elimination_matches[j].state =
              data.leauges[i].double_elimination_matches[j].status;

            data.leauges[i].double_elimination_matches[j].participants =
              data.leauges[i].double_elimination_matches[j].teams;

            for (
              let k = 0;
              k <
              data.leauges[i].double_elimination_matches[j].participants.length;
              k++
            ) {
              data.leauges[i].double_elimination_matches[j].participants[
                k
              ].resultText =
                data.leauges[i].double_elimination_matches[j].participants[
                  k
                ].result;

              data.leauges[i].double_elimination_matches[j].participants[
                k
              ].isWinner =
                data.leauges[i].double_elimination_matches[j].participants[
                  k
                ].is_winner;

              data.leauges[i].double_elimination_matches[j].participants[
                k
              ].name =
                data.leauges[i].double_elimination_matches[j].participants[
                  k
                ].team_name;
            }
          }
          data.leauges[i].double_elimination_matches = groupByKey(
            data.leauges[i].double_elimination_matches,
            "stage"
          );
        }

        if (data.leauges[i].round_robin_matches.length) {
          data.leauges[i].round_robin_matches_init =
            data.leauges[i].round_robin_matches;

          data.leauges[i].round_robin_matches = groupByKey(
            data.leauges[i].round_robin_matches,
            "rounds"
          );

          const teams = [];

          for (
            let j = 0;
            j < data.leauges[i].round_robin_matches_init.length;
            j++
          ) {
            for (
              let k = 0;
              k < data.leauges[i].round_robin_matches_init[j].teams.length;
              k++
            ) {
              teams.push(data.leauges[i].round_robin_matches_init[j].teams[k]);
            }
          }

          const groupTeams = groupByKey(teams, "team_name");
          const teamResults = [];

          for (const [key, value] of Object.entries(groupTeams)) {
            const teamResult = {
              name: key,
              win: 0,
              lose: 0,
              score: 0,
            };
            for (let x = 0; x < value.length; x++) {
              console.log(value[x]);
              if (value[x].score === 3) {
                teamResult.win += 2;
                teamResult.score += 3;
              } else if (value[x].score === 0) {
                teamResult.lose += 2;
                teamResult.score += 0;
              } else if (value[x].score === 1) {
                teamResult.win += 1;
                teamResult.lose += 1;
                teamResult.score += 1;
              }
            }
            teamResults.push(teamResult);
          }

          data.leauges[i].round_robin_results = teamResults.sort(
            (a, b) => b.score - a.score
          );
        }
      }

      setActiveLeauge(data.leauges[0].id);
      setTournament(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const login = () => {
    window.location.href = `https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK}&response_type=code&scope=identify%20email&prompt=none`;
  };

  const getApplicantInfo = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/tournaments/${id}/register/${user.id}`,
        { headers }
      );

      console.log(response);

      const applicant = response.data.data;

      if (applicant) {
        setIsRegistered(true);
        setFullTeamName(applicant.full_team_name);
        setShortTeamName(applicant.short_team_name);
        setCaptainPhone(applicant.captain_phone);
        setCaptainEmail(applicant.captain_email);
        setContact2Phone(applicant.contact_2_phone);
        setContact2Email(applicant.contact_2_email);
        setPlayer1Username(applicant.player_1_username);
        setPlayer1NickName(applicant.player_1_nick_name);
        setPlayer2Username(applicant.player_2_username);
        setPlayer2NickName(applicant.player_2_nick_name);
        setPlayer3Username(applicant.player_3_username);
        setPlayer3NickName(applicant.player_3_nick_name);
        setPlayer4Username(applicant.player_4_username);
        setPlayer4NickName(applicant.player_4_nick_name);
        setPlayer5Username(applicant.player_5_username);
        setPlayer5NickName(applicant.player_5_nick_name);
        setPlayer6Username(applicant.player_6_username);
        setPlayer6NickName(applicant.player_6_nick_name);
        setPlayer7Username(applicant.player_7_username);
        setPlayer7NickName(applicant.player_7_nick_name);
        setUploadedLogoUrl(applicant.team_logo);
        setPreviewLogo(applicant.team_logo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetTournamentRegisterForm = () => {
    setPreviewLogo("");
    setUploadedLogo("");
    setUploadedLogoUrl("");
    setFullTeamName("");
    setShortTeamName("");
    setCaptainEmail("");
    setCaptainPhone("");
    setContact2Email("");
    setContact2Phone("");
    setPlayer1Username("");
    setPlayer1NickName("");
    setPlayer2Username("");
    setPlayer2NickName("");
    setPlayer3Username("");
    setPlayer3NickName("");
    setPlayer4Username("");
    setPlayer4NickName("");
    setPlayer5Username("");
    setPlayer5NickName("");
    setPlayer6Username("");
    setPlayer6NickName("");
    setPlayer7Username("");
    setPlayer7NickName("");
  };

  const registerTournament = async () => {
    if (simpleValidator.current.allValid()) {
      try {
        const headers = {
          Authorization: localStorage.getItem("access_token"),
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/user/tournaments/${id}/register`,
          {
            uploadedLogoUrl: uploadedLogoUrl,
            fullTeamName: fullTeamName,
            shortTeamName: shortTeamName,
            captainPhone: captainPhone,
            captainEmail: captainEmail,
            contact2Phone: contact2Phone,
            contact2Email: contact2Email,
            player1Username: player1Username,
            player2Username: player2Username,
            player3Username: player3Username,
            player4Username: player4Username,
            player5Username: player5Username,
            player6Username: player6Username,
            player7Username: player7Username,
            player1NickName: player1NickName,
            player2NickName: player2NickName,
            player3NickName: player3NickName,
            player4NickName: player4NickName,
            player5NickName: player5NickName,
            player6NickName: player6NickName,
            player7NickName: player7NickName,
            discordUserId: user.id,
          },
          { headers }
        );

        closeModal();
        setIsLoading(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const updateApplicant = async () => {
    if (simpleValidator.current.allValid()) {
      try {
        const headers = {
          Authorization: localStorage.getItem("access_token"),
        };
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/v1/user/tournaments/${id}/register/${user.id}`,
          {
            uploadedLogoUrl: uploadedLogoUrl,
            fullTeamName: fullTeamName,
            shortTeamName: shortTeamName,
            captainPhone: captainPhone,
            captainEmail: captainEmail,
            contact2Phone: contact2Phone,
            contact2Email: contact2Email,
            player1Username: player1Username,
            player2Username: player2Username,
            player3Username: player3Username,
            player4Username: player4Username,
            player5Username: player5Username,
            player6Username: player6Username,
            player7Username: player7Username,
            player1NickName: player1NickName,
            player2NickName: player2NickName,
            player3NickName: player3NickName,
            player4NickName: player4NickName,
            player5NickName: player5NickName,
            player6NickName: player6NickName,
            player7NickName: player7NickName,
            discordUserId: user.id,
          },
          { headers }
        );

        closeModal();
        setIsLoading(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <h3 className="d-flex gap-3 align-items-center">
          <i className="fas fa-chess-knight fa-lg" />{" "}
          <span>
            {isRegistered ? "Update Register Detail" : "Register Tournament"}
          </span>
        </h3>
        <form className="mt-5 d-flex gap-3 flex-column">
          <h5 className="d-flex gap-3 align-items-center">
            <i className="fas fa-edit fa-lg" /> <span>Team Detail</span>
          </h5>
          <div className="input-group d-flex flex-column gap-1 mb-2">
            <label>
              Team Logo <small className="text-xs">(512 x 512)</small>
            </label>
            {previewLogo ? (
              <div
                onClick={() => {
                  document.getElementById("logo-upload").click();
                }}
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: 200, height: 200, borderRadius: 10 }}
                  src={previewLogo}
                  alt="logo"
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  document.getElementById("logo-upload").click();
                }}
                style={{
                  width: 200,
                  height: 200,
                  border: "2px dashed #d1d5db",
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <span>Upload Image</span>
              </div>
            )}
            <input
              onChange={handleUploadLogo}
              id="logo-upload"
              type="file"
              className="d-none"
              accept="image/*"
            />
            <small className="text-danger">
              {simpleValidator.current.message(
                "logo",
                uploadedLogoUrl,
                "required"
              )}
            </small>
          </div>
          <div className="input-group d-flex flex-column gap-1">
            <label>Full Team Name</label>
            <input
              onChange={(e) => setFullTeamName(e.target.value)}
              value={fullTeamName}
              type="text"
              className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
              placeholder="Type your full team name"
              onBlur={() => {
                simpleValidator.current.showMessageFor("fullTeamName");
              }}
            />
            <small className="text-danger">
              {simpleValidator.current.message(
                "fullTeamName",
                fullTeamName,
                "required"
              )}
            </small>
          </div>
          <div className="input-group d-flex flex-column gap-1">
            <label>Short Team Name</label>
            <input
              onChange={(e) => setShortTeamName(e.target.value)}
              value={shortTeamName}
              type="text"
              className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
              placeholder="Type your short team name"
              onBlur={() => {
                simpleValidator.current.showMessageFor("shortTeamName");
              }}
              maxlength="4"
            />
            <small className="text-danger">
              {simpleValidator.current.message(
                "shortTeamName",
                shortTeamName,
                "required|max:4"
              )}
            </small>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Captain Phone</label>
                <input
                  onChange={(e) => setCaptainPhone(e.target.value)}
                  value={captainPhone}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your captain phone"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("captainPhone");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "captainPhone",
                    captainPhone,
                    "required|phone"
                  )}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Captain Email</label>
                <input
                  onChange={(e) => setCaptainEmail(e.target.value)}
                  value={captainEmail}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your captain email"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("captainEmail");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "captainEmail",
                    captainEmail,
                    "required|email"
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Contact2 Phone</label>
                <input
                  onChange={(e) => setContact2Phone(e.target.value)}
                  value={contact2Phone}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your contact2 phone"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("contact2Phone");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "contact2Phone",
                    contact2Phone,
                    "required|phone"
                  )}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Contact2 Email</label>
                <input
                  onChange={(e) => setContact2Email(e.target.value)}
                  value={contact2Email}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your contact2 email"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("contact2Email");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "contact2Email",
                    contact2Email,
                    "required|email"
                  )}
                </small>
              </div>
            </div>
          </div>
          <hr />

          <h5 className="d-flex gap-3 align-items-center">
            <i className="fas fa-users fa-lg" /> <span>Player Details</span>
          </h5>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 1</label>
                <input
                  onChange={(e) => setPlayer1Username(e.target.value)}
                  value={player1Username}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 1"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player1Username");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player1Username",
                    player1Username,
                    "required"
                  )}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 1 Nickname</label>
                <input
                  onChange={(e) => setPlayer1NickName(e.target.value)}
                  value={player1NickName}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 1"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player1NickName");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player1NickName",
                    player1NickName,
                    "required"
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 2</label>
                <input
                  onChange={(e) => setPlayer2Username(e.target.value)}
                  value={player2Username}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 2"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player2Username");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player2Username",
                    player2Username,
                    "required"
                  )}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 2 Nickname</label>
                <input
                  onChange={(e) => setPlayer2NickName(e.target.value)}
                  value={player2NickName}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 2"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player2NickName");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player2NickName",
                    player2NickName,
                    "required"
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 3</label>
                <input
                  onChange={(e) => setPlayer3Username(e.target.value)}
                  value={player3Username}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 3"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player3Username");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player3Username",
                    player3Username,
                    "required"
                  )}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 3 Nickname</label>
                <input
                  onChange={(e) => setPlayer3NickName(e.target.value)}
                  value={player3NickName}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 3"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player3NickName");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player3NickName",
                    player3NickName,
                    "required"
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 4</label>
                <input
                  onChange={(e) => setPlayer4Username(e.target.value)}
                  value={player4Username}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 4"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player4Username");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player4Username",
                    player4Username,
                    "required"
                  )}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 4 Nickname</label>
                <input
                  onChange={(e) => setPlayer4NickName(e.target.value)}
                  value={player4NickName}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 1"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player4NickName");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player4NickName",
                    player4NickName,
                    "required"
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 5</label>
                <input
                  onChange={(e) => setPlayer5Username(e.target.value)}
                  value={player5Username}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 5"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player5Username");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player5Username",
                    player5Username,
                    "required"
                  )}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 5 Nickname</label>
                <input
                  onChange={(e) => setPlayer5NickName(e.target.value)}
                  value={player5NickName}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 5"
                  onBlur={() => {
                    simpleValidator.current.showMessageFor("player5NickName");
                  }}
                />
                <small className="text-danger">
                  {simpleValidator.current.message(
                    "player5NickName",
                    player5NickName,
                    "required"
                  )}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 6 (Optional)</label>
                <input
                  onChange={(e) => setPlayer6Username(e.target.value)}
                  value={player6Username}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 6"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 6 Nickname (Optional)</label>
                <input
                  onChange={(e) => setPlayer6NickName(e.target.value)}
                  value={player6NickName}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 6"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 7 (Optional)</label>
                <input
                  onChange={(e) => setPlayer7Username(e.target.value)}
                  value={player7Username}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 7"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-group d-flex flex-column gap-1">
                <label>Player 7 Nickname (Optional)</label>
                <input
                  onChange={(e) => setPlayer7NickName(e.target.value)}
                  value={player7NickName}
                  type="text"
                  className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
                  placeholder="Type your player 7"
                />
              </div>
            </div>
          </div>
          {isRegistered ? (
            <button
              onClick={updateApplicant}
              className="py-2 text-white border-0 mt-4 rounded login-modal"
              type="button"
            >
              Update
            </button>
          ) : (
            <button
              onClick={registerTournament}
              className="py-2 text-white border-0 mt-4 rounded login-modal"
              type="button"
            >
              Submit
            </button>
          )}
        </form>
      </Modal>
      <HeaderOne />
      <main>
        <section className="breadcrumb-area breadcrumb-bg">
          <div className="breadcrumb-overflow">
            <div className="container">
              <div className="breadcrumb-inner">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="breadcrumb-content">
                      <div className="d-flex flex-column gap-4">
                        <h1>{!isLoading && tournament.tournament_name}</h1>
                        <h5>
                          Amount Teams:{"  "}
                          {!isLoading && tournament.participate_count} /{" "}
                          {!isLoading && tournament.amount_teams}
                        </h5>
                        <h5>
                          Register Duration:{" "}
                          {!isLoading &&
                            moment(tournament.register_start_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                          -{" "}
                          {!isLoading &&
                            moment(tournament.register_end_date).format(
                              "DD/MM/YYYY"
                            )}
                        </h5>
                        <h5>
                          Tournament Duration:{" "}
                          {!isLoading &&
                            moment(tournament.start_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                          -{" "}
                          {!isLoading &&
                            moment(tournament.end_date).format("DD/MM/YYYY")}
                        </h5>
                        {!isLoading &&
                        !moment().isBetween(
                          moment(tournament.register_start_date),
                          moment(tournament.register_end_date),
                          null,
                          []
                        ) ? (
                          <button
                            type="button"
                            className="btn btn-not-allowed d-flex justify-content-center gap-2  border-0 rounded"
                            style={{ width: 350, cursor: "not-allowed" }}
                          >
                            Not In Register Duration
                          </button>
                        ) : !isLoading && !user ? (
                          <button
                            onClick={login}
                            type="button"
                            className="btn btn-custom-login d-flex gap-2"
                            style={{ width: 275 }}
                          >
                            <i className="fab fa-discord fa-lg" />
                            <span>Login With Discord to register</span>
                          </button>
                        ) : !isLoading && isRegistered ? (
                          <button
                            onClick={openModal}
                            type="button"
                            className="btn btn-edit d-flex justify-content-center gap-2  border-0 rounded"
                            style={{
                              width: 275,
                              backgroundColor: "#ff8503",
                            }}
                          >
                            Update Team Detail
                          </button>
                        ) : !isLoading &&
                          tournament.participate_count >=
                            tournament.amount_teams ? (
                          <button
                            type="button"
                            className="btn btn-not-allowed d-flex justify-content-center gap-2  border-0 rounded"
                            style={{ width: 275, cursor: "not-allowed" }}
                          >
                            Full Participates
                          </button>
                        ) : (
                          <div className="row">
                            <div className="col-md-6">
                              <button
                                onClick={openModal}
                                type="button"
                                className="btn w-100 d-flex justify-content-center"
                              >
                                Register Tournament
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-none d-md-block">
                    <div className="breadcrumb-img text-center text-lg-end">
                      <img
                        style={{ width: "100%" }}
                        src="/img/kinesis_1.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <motion.div
              initial={{ x: -380, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 2.5,
              }}
              className="breadcrumb-shape shape-1"
            >
              <img src="assets/img/bg/breadcrumb_shape01.png" alt="" />
            </motion.div>
            <motion.div
              initial={{ x: 380, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 2.5,
              }}
              className="breadcrumb-shape shape-2"
            >
              <img src="assets/img/bg/breadcrumb_shape02.png" alt="" />
            </motion.div>
          </div>
        </section>
        {!isLoading && tournament.video_url && (
          <section className="blog-area black-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex flex-column align-items-center">
                    <h4 className="widget-title">Teaser</h4>
                    <div className="row w-100 justify-content-center">
                      <div className="col-md-6">
                        <ReactPlayer width="100%" url={tournament.video_url} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="blog-area black-bg">
          <div className="container">
            <div className="row mb-4">
              <div className="col-lg-12">
                <div className="d-flex justify-content-between">
                  <h4 className="widget-title">Tournament Description</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="just-gamer-content-box">
                  <div className="ql-editor">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: !isLoading && tournament.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="blog-area black-bg">
          <div className="container">
            <div className="row mb-4">
              <div className="col-lg-12">
                <div className="d-flex justify-content-between">
                  <h4 className="widget-title">Leauge Brackets</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <section className="just-gamer-area pb-105">
                  <div className="just-gamer-shape" />
                  <div className="container">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-xl-12 order-2 order-xl-0">
                        <div
                          id="bracket-section"
                          className="just-gamer-content-box"
                        >
                          <div className="d-flex flex-wrap gap-4 mb-4">
                            {!isLoading &&
                              tournament.leauges.map((leauge) => {
                                return (
                                  <div
                                    onClick={() => setActiveLeauge(leauge.id)}
                                    style={{ cursor: "pointer" }}
                                    className="px-4 py-1 rounded bg-secondary"
                                  >
                                    {leauge.leauge_name}
                                  </div>
                                );
                              })}
                          </div>
                          {!isLoading &&
                            tournament.leauges.map((leauge) => {
                              if (leauge.id === activeLeauge) {
                                return (
                                  <div>
                                    <div className="rc-post-list d-flex justify-content-center mt-3">
                                      {leauge.leauge_type ===
                                      "Single Elimination" ? (
                                        <div className="d-flex flex-column gap-3">
                                          <div>{leauge.leauge_type}</div>
                                          <SingleEliminationBracket
                                            matches={
                                              leauge.leauge_type ===
                                                "Single Elimination" &&
                                              leauge.single_elimination_matches
                                            }
                                            matchComponent={Match}
                                            svgWrapper={({
                                              children,
                                              ...props
                                            }) => (
                                              <SVGViewer
                                                width={bracketWidth - 50}
                                                {...props}
                                              >
                                                {children}
                                              </SVGViewer>
                                            )}
                                          />
                                        </div>
                                      ) : leauge.leauge_type ===
                                        "Double Elimination" ? (
                                        <DoubleEliminationBracket
                                          matches={
                                            leauge.leauge_type ===
                                              "Double Elimination" &&
                                            leauge.double_elimination_matches
                                          }
                                          matchComponent={Match}
                                          svgWrapper={({
                                            children,
                                            ...props
                                          }) => (
                                            <SVGViewer
                                              width={1200}
                                              height={1000}
                                              {...props}
                                            >
                                              {children}
                                            </SVGViewer>
                                          )}
                                        />
                                      ) : (
                                        <div className="d-flex flex-column gap-3 w-100">
                                          <div className="d-flex flex-column gap-2">
                                            <h4>Result</h4>
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="bg-primary px-4 pt-4 pb-2 w-100 rounded">
                                                  <div className="row mb-4">
                                                    <div className="col-2">
                                                      Rank
                                                    </div>
                                                    <div className="col-2">
                                                      Team Name
                                                    </div>
                                                    <div className="col-2 text-center">
                                                      Win
                                                    </div>
                                                    <div className="col-2 text-center">
                                                      Lose
                                                    </div>
                                                    <div className="col-2 text-center">
                                                      Score
                                                    </div>
                                                  </div>
                                                  {leauge.round_robin_results.map(
                                                    (result, index) => {
                                                      return (
                                                        <div className="row mb-2">
                                                          <div className="col-2">
                                                            {index + 1}
                                                          </div>
                                                          <div className="col-2">
                                                            {result.name}
                                                          </div>
                                                          <div className="col-2 text-center">
                                                            {result.win}
                                                          </div>
                                                          <div className="col-2 text-center">
                                                            {result.lose}
                                                          </div>
                                                          <div className="col-2 text-center">
                                                            {result.score}
                                                          </div>
                                                          <hr className="mt-2" />
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                  <div className="d-flex justify-content-between align-items-center">
                                                    <p>
                                                      Total{" "}
                                                      {
                                                        leauge
                                                          .round_robin_results
                                                          .length
                                                      }{" "}
                                                      teams
                                                    </p>
                                                    <p>
                                                      Matches{" "}
                                                      {
                                                        leauge
                                                          .round_robin_matches_init
                                                          .length
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div>{leauge.leauge_type}</div>
                                          <div className="row gx-4 gy-4">
                                            {Object.entries(
                                              leauge.round_robin_matches
                                            ).map(([key, value]) => {
                                              return (
                                                <div className="col-md-3">
                                                  <h5 className="mb-4">
                                                    Round {key}
                                                  </h5>
                                                  <div className="bg-secondary p-3 rounded">
                                                    {value.map((match) => {
                                                      return (
                                                        <div className="d-flex gap-2 mb-3 align-items-center">
                                                          <div className="p-2 rounded bg-dark flex-grow-1 d-flex justify-content-between">
                                                            <span>
                                                              {
                                                                match.teams[0]
                                                                  .team_name
                                                              }
                                                            </span>
                                                            <div className="bg-primary px-1 rounded">
                                                              {match.teams[0]
                                                                .result
                                                                ? match.teams[0]
                                                                    .result
                                                                : 0}
                                                            </div>
                                                          </div>
                                                          <span>vs</span>
                                                          <div className="p-2 rounded bg-dark flex-grow-1 d-flex justify-content-between">
                                                            <span>
                                                              {
                                                                match.teams[1]
                                                                  .team_name
                                                              }
                                                            </span>
                                                            <div className="bg-primary px-1 rounded">
                                                              {match.teams[1]
                                                                .result
                                                                ? match.teams[1]
                                                                    .result
                                                                : 0}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default TournamentDetail;
