import React from "react";

function Footer() {
  return (
    <footer>
      <div className="footer-top-wrap">
        <div className="container">
          <div className="footer-menu-wrap">
            <div className="row align-items-center">
              <div className="col-lg-11">
                <div className="f-logo">
                  <div className="quick-link-list">
                    <ul>
                      <li>
                        <a href="/contact">FAQ</a>
                      </li>
                      <li>
                        <a href="/contact">Help Center</a>
                      </li>
                      <li>
                        <a href="/contact">Terms of Use</a>
                      </li>
                      <li>
                        <a href="/contact">Privacy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-1">
                <div className="footer-menu">
                  <a href="/index-3">
                    <img src="/img/logo.png" alt="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-quick-link-wrap">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-8">
                <nav>
                  <div className="footer-search">
                    Copyright © {new Date().getFullYear()} All Rights Reserved
                    By <span>Kongor-TH</span>
                  </div>
                </nav>
              </div>
              <div className="col-lg-5 col-md-4">
                <div className="footer-social">
                  <ul>
                    <li>
                      <a
                        href="https:// www.youtube.com/@HoNCommunityThailand"
                        target="_blank"
                      >
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.com/invite/honth"
                        target="_blank"
                      >
                        <i className="fab fa-discord" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
