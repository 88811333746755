import React, { useState } from "react";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";

function Redeem() {
  return (
    <>
      <HeaderOne />
      <main>
        <section className="breadcrumb-area breadcrumb-bg py-5">
          <div className="breadcrumb-overflow">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-5">
                  <div className="blog-widget">
                    <h4 className="widget-title">Quest</h4>
                    <div className="sidebar-newsletter text-left">
                      <div className="sn-thumb">
                        <div className="flex flex-col">
                          <h3>1. Donate</h3>
                          <h5>Donate Amount: 900 THB</h5>
                          <h5>Quota: 10</h5>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar-newsletter text-left mt-3">
                      <div className="sn-thumb">
                        <div className="flex flex-col">
                          <h3>2. Play Match</h3>
                          <h5>Match Played: 12</h5>
                          <h5>Quota: 12</h5>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar-newsletter text-left mt-3">
                      <div className="sn-thumb">
                        <div className="flex flex-col">
                          <h3>3. Get a reward</h3>
                          <button
                            style={{ background: "#5865f2", border: 0 }}
                            type="button"
                            className="py-2 rounded mt-3 w-100 text-white"
                          >
                            Redeem
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blog-widget h-100">
                    <h4 className="widget-title">Redeem History</h4>
                    <div
                      style={{ height: 500, overflowY: "scroll" }}
                      className="sidebar-newsletter text-left rounded"
                    >
                      <div className="sn-thumb">
                        {["", "", "", "", "", ""].map((i, index) => {
                          return (
                            <div className="d-flex justify-content-between align-items-center bg-secondary p-4 rounded mb-3">
                              <div className="d-flex flex-column">
                                <h5 className="my-auto">
                                  {index + 1}. Ultra Rare Item#1
                                </h5>
                                <p className="my-auto">Super rare item</p>
                              </div>
                              <div className="d-flex flex-column align-items-end">
                                <h5 className="my-auto">
                                  Donate Amount: 900 THB
                                </h5>
                                <p className="my-auto">08/10/2023 08.00PM</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Redeem;
