import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import axios from "axios";

function Tournament() {
  const { id, teamId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));

  useEffect(() => {
    getTeamDetail();
  }, [isLoading]);

  const getTeamDetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/tournaments/${id}/teams/${teamId}`
      );

      const data = response.data.data;
      console.log(data);
      setTeam(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area black-bg">
          {!isLoading && (
            <div className="container">
              <div className="row">
                <div className="col-lg-12 px-5 mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>Tournament: {team.tournament.tournament_name}</h2>
                    <Link className="btn" to={`/tournaments/${id}/teams`}>
                      Back
                    </Link>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="blog-sidebar">
                    {!isLoading && (
                      <div className="blog-widget">
                        <h4 className="widget-title">{team.full_team_name}</h4>
                        <div className="rc-post-list">
                          <img
                            className="mt-3 mb-5"
                            style={{ width: 100, height: 100 }}
                            src={team.team_logo}
                            alt="logo"
                          />
                          <p>Short Name: {team.short_team_name}</p>
                          <p>Captain Username: {team.player_1_username}</p>
                          <p>Captain Nickname: {team.player_1_nick_name}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>{" "}
                <div className="col-lg-7">
                  <div className="blog-sidebar">
                    <div className="blog-widget">
                      <h4 className="widget-title">Members</h4>
                      <div className="rc-post-list">
                        <p>
                          Player 1: {team.player_1_username} (
                          {team.player_1_nick_name})
                        </p>
                        <p>
                          Player 2: {team.player_2_username} (
                          {team.player_2_nick_name})
                        </p>
                        <p>
                          Player 3: {team.player_3_username} (
                          {team.player_3_nick_name})
                        </p>
                        <p>
                          Player 4: {team.player_4_username} (
                          {team.player_4_nick_name})
                        </p>
                        <p>
                          Player 5: {team.player_5_username} (
                          {team.player_5_nick_name})
                        </p>
                        {team.player_6_username ? (
                          <p>
                            Player 6: {team.player_6_username} (
                            {team.player_6_nick_name})
                          </p>
                        ) : (
                          <p>Player 6: -</p>
                        )}
                        {team.player_7_username ? (
                          <p>
                            Player 7: {team.player_7_username} (
                            {team.player_7_nick_name})
                          </p>
                        ) : (
                          <p>Player 7: -</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Tournament;
