import React from "react";
import "animate.css";
function Banner() {
  return (
    <div id="parallax" className="banner-area banner-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-8 col-lg-9">
            <div className="banner-content text-center">
              <h4
                style={{ fontSize: 60 }}
                className="small-title animate__animated animate__fadeInUp animate__delay-.2s font-scurlock"
              >
                Hon Community Thailand
              </h4>
              <a className="btn" href="https://kongor.online/register/" target="_blank">
                Register
              </a>
              <div className="banner-btn animate__animated animate__fadeInUp animate__delay-.6s"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
