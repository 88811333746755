import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import Banner from "../components/headerone/Banner";
import CounterArea from "../components/headertwo/CounterArea";
import TopBlogs from "../components/blog/TopBlogs";
import { useUserContext } from "../contexts/UserContext";
import * as rdd from "react-device-detect";

function IndexOne() {
  const { user, userAction } = useUserContext();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    getSetting();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("is_hide_main_modal") === "false" &&
      (localStorage.getItem("is_hide_sync_username_modal") === "true" || !user)
    ) {
      setIsOpen(true);
    }
  }, []);

  const getSetting = async () => {
    const headers = {
      Authorization: localStorage.getItem("access_token"),
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/settings`,
        { headers }
      );
      console.log(response);
      setModalImage(response.data.data.homepage_modal_image_url);
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyles = {
    content: {
      padding: "50px 40px",
      top: rdd.isMobile ? "20%" : "25%",
      left: rdd.isMobile ? "15%" : "35%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      backgroundColor: "#1f2029",
      border: 0,
      width: rdd.isMobile ? "70%" : "35%",
      height: rdd.isMobile ? "50%" : "50%",
      borderRadius: 15,
      boxShadow:
        "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    },
    overlay: {
      zIndex: 9999,
      backgroundColor: "rgb(0,0,0,0.5)",
    },
  };

  const handleShowMainModal = () => {
    if (localStorage.getItem("is_hide_main_modal") === "false") {
      localStorage.setItem("is_hide_main_modal", "true");
    } else {
      localStorage.setItem("is_hide_main_modal", "false");
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {modalImage && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyles}
        >
          <div style={{ width: "100%" }}>
            <img
              style={{ height: 320, objectFit: "contain" }}
              className="w-100"
              src={modalImage}
              alt="news"
            />
          </div>
          <div
            style={{ position: "absolute", bottom: 30 }}
            className="d-flex gap-2"
          >
            <input
              id="disable-modal"
              onChange={handleShowMainModal}
              type="checkbox"
            />
            <label for="disable-modal">Not show this pop-up again</label>
          </div>
        </Modal>
      )}
      <HeaderOne />
      <main>
        <Banner />
        <TopBlogs />
        <CounterArea />
      </main>
      <Footer />
    </>
  );
}

export default IndexOne;
