import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import $ from "jquery";
import "../../App.css";
import { useUserContext } from "../../contexts/UserContext";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import * as rdd from "react-device-detect";

function HeaderOne() {
  const { user, userAction } = useUserContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [syncError, setSyncError] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("is_hide_sync_username_modal")) {
      localStorage.setItem("is_hide_sync_username_modal", "false");
    }

    if (!localStorage.getItem("is_hide_main_modal")) {
      localStorage.setItem("is_hide_main_modal", "false");
    }

    if (
      user &&
      user.game_username === null &&
      localStorage.getItem("is_hide_sync_username_modal") === "false"
    ) {
      setIsOpen(true);
    }
  }, [user]);

  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      // var mobileMenuContent = $(".menu-area .push-menu").html();
      // $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, [user]);

  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
    /*=============================================
	=     Menu sticky & Scroll to top      =
=============================================*/
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-top-fixed").removeClass("header-fixed-position");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-top-fixed").addClass("header-fixed-position");
      }
    });

    /*=============================================
	=    		 Scroll Up  	         =
=============================================*/
    if ($(".scroll-to-target").length) {
      $(".scroll-to-target").on("click", function () {
        var target = $(this).attr("data-target");
        // animate
        $("html, body").animate(
          {
            scrollTop: $(target).offset().top,
          },
          1000
        );
      });
    }
  }, [user]);

  const modalStyles = {
    content: {
      padding: "50px 40px",
      top: rdd.isMobile ? "20%" : "30%",
      left: rdd.isMobile ? "15%" : "40%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      backgroundColor: "#1f2029",
      border: 0,
      width: rdd.isMobile ? "70%" : "25%",
      height: rdd.isMobile ? "70%" : "46%",
      borderRadius: 15,
      boxShadow:
        "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    },
    overlay: {
      zIndex: 9999,
      backgroundColor: "rgb(0,0,0,0.5)",
    },
  };

  function login() {
    window.location.href = `https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK}&response_type=code&scope=identify%20email&prompt=none`;
  }

  function closeModal() {
    setIsOpen(false);
  }

  const logout = () => {
    localStorage.removeItem("access_token");
    document.getElementById("close-user-detail").click();
    userAction.storeUserDetail("");
  };

  const handleShowSyncUsernameModal = () => {
    if (localStorage.getItem("is_hide_sync_username_modal") === "false") {
      localStorage.setItem("is_hide_sync_username_modal", "true");
    } else {
      localStorage.setItem("is_hide_sync_username_modal", "false");
    }
  };

  const syncGameAccount = async (e) => {
    e.preventDefault();
    setSyncError("");
    const headers = {
      Authorization: localStorage.getItem("access_token"),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/sync-game-username`,
        {
          username: username,
          password: password,
        },
        { headers }
      );

      console.log(response);

      userAction.storeUserDetail(response.data.data);

      closeModal();
      localStorage.setItem("is_hide_sync_username_modal", "true");

      toast.success("Sync Game Username Success !!");
    } catch (error) {
      console.log(error);
      setSyncError("Username or Password is incorrect.");
    }
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <h3 className="d-flex gap-3 align-items-center">
          <i className="fas fa-gamepad fa-lg" /> <span> Sync Game Account</span>
        </h3>
        <form
          onSubmit={(e) => syncGameAccount(e)}
          className=" d-flex gap-3 flex-column mt-5"
        >
          <div className="input-group d-flex flex-column gap-1">
            <label>Username</label>
            <input
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              type="text"
              className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
              placeholder="Type your game username"
            />
          </div>
          <div className="input-group d-flex flex-column gap-1">
            <label>Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              className="form-control w-100 rounded bg-dark border-0 py-2 shadow-lg text-white"
              placeholder="Type your game password"
            />
            {syncError && (
              <small className="text-danger text-end mt-1">{syncError}</small>
            )}
          </div>
          <button
            className={
              syncError
                ? "py-2 text-white border-0 rounded login-modal"
                : "py-2 text-white border-0 rounded login-modal mt-2"
            }
            type="submit"
          >
            Confirm
          </button>
          <div
            style={{ position: "absolute", bottom: 40 }}
            className="d-flex gap-2"
          >
            <input
              id="disable-modal"
              onChange={handleShowSyncUsernameModal}
              type="checkbox"
            />
            <label for="disable-modal">Not show this pop-up again</label>
          </div>
        </form>
      </Modal>
      <header>
        <div id="header-top-fixed" />
        <div id="sticky-header" className="menu-area main-header">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars" />
                </div>
                <div className="menu-wrap main-menu">
                  <nav className="menu-nav show">
                    <div className="logo">
                      <a href="/#">
                        <img src="/img/logo.png" alt="" />
                      </a>
                    </div>
                    <div className="navbar-wrap push-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li className=" menu-item-has-children">
                          <a href="/#">Home</a>
                        </li>
                        <li>
                          <Link to="/download">Download</Link>
                        </li>
                        <li>
                          <Link to="/tournaments">Tournament</Link>
                        </li>
                        <li>
                          <Link to="/donate">Donate</Link>
                        </li>
                        <li>
                          <Link to="/wheel">Wheel</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/report">Report</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="header-action">
                      <ul>
                        <li>
                          {user ? (
                            <div className="d-flex align-items-center gap-2">
                              <img
                                style={{
                                  width: 35,
                                  height: 35,
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                                src={
                                  user && user.avatar
                                    ? `https://cdn.discordapp.com/avatars/${user.discord_id}/${user.avatar}.png`
                                    : "/assets/img/logo/discord.png"
                                }
                                alt="avatar"
                              />{" "}
                              <span style={{ fontSize: 14 }}>
                                {user.username}
                              </span>
                            </div>
                          ) : (
                            <button
                              onClick={login}
                              className="btn btn-custom-login d-flex gap-2"
                              type="button"
                            >
                              <i className="fab fa-discord fa-lg" />
                              <span>Login With Discord</span>
                            </button>
                          )}
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {/* Mobile Menu  */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times" />
                    </div>
                    <div className="nav-logo">
                      <Link to="/">
                        <img
                          style={{
                            width: 50,
                            height: 50,
                            position: "absolute",
                          }}
                          src="/img/logo.png"
                          alt=""
                          title="true"
                        />
                      </Link>
                    </div>
                    {user ? (
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex flex-column align-items-center justify-content-center gap-2 px-4 py-1">
                          <img
                            style={{
                              width: 35,
                              height: 35,
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                            src={
                              user && user.avatar
                                ? `https://cdn.discordapp.com/avatars/${user.discord_id}/${user.avatar}.png`
                                : "/assets/img/logo/discord.png"
                            }
                            alt="avatar"
                          />{" "}
                          <span style={{ fontSize: 14 }}>{user.username}</span>
                          <small style={{ fontSize: 12 }}>{user.email}</small>
                          <small style={{ fontSize: 12 }}>
                            Game: {user.game_username}
                          </small>
                        </div>{" "}
                        <div className="d-flex flex-column gap-2 mt-4">
                          <Link
                            className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                            to="/wheel/history"
                          >
                            <i className="fas fa-dice" />
                            <span>Wheel History</span>
                          </Link>
                          <Link
                            className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                            to="/donate/history"
                          >
                            <i className="fas fa-money-bill" />
                            <span>Donate History</span>
                          </Link>
                          <Link
                            className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                            to="/report/history"
                          >
                            <i className="fas fa-ban" />
                            <span>Report History</span>
                          </Link>
                          <Link
                            className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                            to="/setting"
                          >
                            <i className="fas fa-user" />
                            <span>Setting</span>
                          </Link>
                          <button
                            onClick={logout}
                            type="button"
                            className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                          >
                            <i className="fas fa-door-open" />
                            <span>Log out</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center mt-5">
                        <button
                          onClick={login}
                          className="btn btn-custom-login d-flex gap-2 mb-3"
                          type="button"
                        >
                          <i className="fab fa-discord fa-lg" />
                          <span>Login With Discord</span>
                        </button>
                      </div>
                    )}
                    <div className="menu-outer">
                      {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                      <ul className="navigation">
                        <li className=" menu-item-has-children">
                          <a href="/#">Home</a>
                        </li>
                        <li>
                          <Link to="/download">Download</Link>
                        </li>
                        <li>
                          <Link to="/tournaments">Tournament</Link>
                        </li>
                        <li>
                          <Link to="/donate">Donate</Link>
                        </li>
                        <li>
                          <Link to="/wheel">Wheel</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/report">Report</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li>
                          <a
                            href="https:// www.youtube.com/@HoNCommunityThailand"
                            target="_blank"
                          >
                            <i className="fab fa-youtube" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://discord.com/invite/honth"
                            target="_blank"
                          >
                            <i className="fab fa-discord" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
          <div className={user ? "header-icon" : "d-none"}>
            <a href="/#" className="menu-tigger">
              <i className="fas fa-bars" />
            </a>
          </div>
        </div>
        {/* offCanvas-menu */}
        {user && (
          <div className="extra-info">
            <div className="close-icon menu-close">
              <button id="close-user-detail">
                <i className="far fa-window-close" />
              </button>
            </div>
            <div className="logo-side mb-30">
              <div className="d-flex flex-row gap-4 align-items-center">
                <img
                  style={{
                    width: 75,
                    height: 75,
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  src={
                    user && user.avatar
                      ? `https://cdn.discordapp.com/avatars/${user.discord_id}/${user.avatar}.png`
                      : "/assets/img/logo/discord.png"
                  }
                  alt="avatar"
                />
                <div className="d-flex flex-column gap-1">
                  <span>{user.username}</span>
                  <small style={{ fontSize: 12 }}>{user.email}</small>
                  <small style={{ fontSize: 12 }}>
                    Game:{" "}
                    {user.game_username !== null ? user.game_username : "-"}
                  </small>
                </div>
              </div>
              <div className="d-flex flex-column gap-2 mt-4">
                <Link
                  className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                  to="/wheel/history"
                >
                  <i className="fas fa-dice" />
                  <span>Wheel History</span>
                </Link>
                <Link
                  className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                  to="/donate/history"
                >
                  <i className="fas fa-money-bill" />
                  <span>Donate History</span>
                </Link>
                <Link
                  className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                  to="/report/history"
                >
                  <i className="fas fa-ban" />
                  <span>Report History</span>
                </Link>
                <Link
                  className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                  to="/setting"
                >
                  <i className="fas fa-user" />
                  <span>Setting</span>
                </Link>
                <button
                  onClick={logout}
                  type="button"
                  className="px-4 py-2 border-0 text-white bg-transparent d-flex gap-2 align-items-center"
                >
                  <i className="fas fa-door-open" />
                  <span>Log out</span>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="offcanvas-overly" />
        {/* offCanvas-menu-end  */}
      </header>
    </>
  );
}

export default HeaderOne;
