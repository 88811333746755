import React, { useState, useEffect } from "react";
import axios from "axios";
import CountUp from "react-countup";

function CounterArea() {
  const getStats = async () => {
    try {
      const response = await axios.get(
        "https://api.kongor.online/stats/portal"
      );

      console.log(response, "sa");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <section className="counter-area pt-160 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-9 d-flex justify-content-center">
            <div className="counter-item mb-50">
              <div className="counter-img">
                <img src="assets/img/icon/gp_icon01.png" alt="" />
              </div>
              <div className="counter-content">
                <h2 className="count">
                  <span /> <CountUp end={310537} duration={3} />
                </h2>
                <p>Total Player</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9 d-flex justify-content-center">
            <div className="counter-item mb-50">
              <div className="counter-img">
                <img src="assets/img/icon/gp_icon02.png" alt="" />
              </div>
              <div className="counter-content">
                <h2 className="count">
                  <span /> <CountUp end={3302} duration={3} />
                </h2>
                <p>Online Player</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9 d-flex justify-content-center">
            <div className="counter-item mb-50">
              <div className="counter-img">
                <img src="assets/img/icon/gp_icon06.png" alt="" />
              </div>
              <div className="counter-content">
                <h2 className="count">
                  <span /> <CountUp end={278} duration={3} />
                </h2>
                <p>Matches In Progress</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CounterArea;
