import React, { createContext, useContext, useState } from "react";
import { node } from "prop-types";

const UserContext = createContext({});

export function useUserContext() {
  return useContext(UserContext);
}

function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  function storeUserDetail(user) {
    console.log(user, "account");
    setUser(user);
  }

  const userStore = {
    user,
    userAction: { storeUserDetail },
  };

  return (
    <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
  );
}

UserProvider.prototype = {
  children: node.isRequired,
};

export default UserProvider;
