import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import axios from "axios";
import { useUserContext } from "../contexts/UserContext";
import { Buffer } from "buffer";
import toast, { Toaster } from "react-hot-toast";

function Donate() {
  const { user, userAction } = useUserContext();
  const [stage, setStage] = useState("");
  const [amount, setAmount] = useState("");
  const [phone, setPhone] = useState("");
  const [activeDonateType, setActiveDonateType] = useState("QR CODE");
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [rawHTML, setRawHTML] = useState("");
  const [isCheckQRCodePayment, setIsCheckQRCodePayment] = useState(false);
  const [isCheckTrueWalletPayment, setIsCheckTrueWalletPayment] =
    useState(false);
  const [qrCodeRef, setQRCodeRef] = useState("");
  const [trueWalletRef, setTrueWalletRef] = useState("");

  useEffect(() => {
    if (isCheckQRCodePayment === true) {
      setInterval(checkQRCodePayment, 2000);
    }
  }, [isCheckQRCodePayment]);

  useEffect(() => {
    if (isCheckTrueWalletPayment === true) {
      setInterval(checkTrueWalletPayment, 2000);
    }
  }, [isCheckTrueWalletPayment]);

  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const login = () => {
    window.location.href = `https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK}&response_type=code&scope=identify%20email&prompt=none`;
  };

  const startDonate = async (e) => {
    e.preventDefault();
    if (activeDonateType === "QR CODE") {
      await donateByQrCode();
    } else {
      await donateByTrueMoneyWallet();
    }
  };

  const checkQRCodePayment = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
        "Content-Type": "	application/x-www-form-urlencoded",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/donate/qr-code/check`,
        {
          refId: qrCodeRef,
        },
        {
          headers,
        }
      );
      console.log(response);
      toast.success("Donate Success !!");
      setTimeout(() => (window.location.href = "/donate/history"), 1500);
    } catch (error) {
      console.log(error);
    }
  };

  const checkTrueWalletPayment = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
        "Content-Type": "	application/x-www-form-urlencoded",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/donate/true-wallet/check`,
        {
          refId: trueWalletRef,
        },
        {
          headers,
        }
      );
      console.log(response);
      toast.success("Donate Success !!");
      setTimeout(() => (window.location.href = "/donate/history"), 1500);
    } catch (error) {
      console.log(error);
    }
  };

  const donateByQrCode = async () => {
    const ref = makeid(15);
    setQRCodeRef(ref);
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
        "Content-Type": "	application/x-www-form-urlencoded",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_GB_API_URL_LIVE}/v3/qrcode`,
        {
          token: process.env.REACT_APP_GB_CUSTOMER_KEY,
          amount: parseFloat(amount),
          referenceNo: ref,
          detail: user.id + "|" + ref,
          backgroundUrl: `${process.env.REACT_APP_API_URL}/api/v1/guest/donate/qr-code/hook`,
        },
        {
          responseType: "arraybuffer",
          headers,
        }
      );
      let base64ImageString = Buffer.from(response.data, "binary").toString(
        "base64"
      );
      setQrCodeImage("data:image/png;base64," + base64ImageString);
      setStage("qr-code");
      setIsCheckQRCodePayment(true);
    } catch (error) {
      console.log(error);
    }
  };

  const donateByTrueMoneyWallet = async () => {
    const ref = makeid(15);
    setTrueWalletRef(ref);
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/donate/true-money-wallet`,
        {
          phone: phone,
          amount: parseFloat(amount),
          referenceNo: ref,
        },
        { headers }
      );

      let html = response.data.data.replace(
        "<br /><br /><br /><br /><br /><br /><br />",
        ""
      );

      console.log(html);
      setRawHTML(html);
      setStage("true-wallet");
      setIsCheckTrueWalletPayment(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <HeaderOne />
      <main>
        <section className="breadcrumb-area breadcrumb-bg py-5">
          <div className="breadcrumb-overflow">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-6">
                  <div className="blog-widget">
                    <div className="sidebar-newsletter text-center">
                      <div className="d-flex flex-column gap-3 align-items-center">
                        <h4 className="widget-title">รายละเอียด</h4>
                        <p>
                          ระดมทุนเพื่อให้เราสามารถมอบประสิทธิภาพการเล่นเกมที่ดีที่สุดให้กับชุมชน
                          HoN Communnity Thailand
                          เงินบริจาคทั้งหมดจะถูกนำไปใช้จ่ายค่าบริการเซิร์ฟเวอร์และส่วนที่เหลือจะถูกจ่ายคืนให้กับชุมชนผ่านการแข่งขัน
                          และกิจกรรมต่างๆที่จะจัดขึ้นในอนาคต
                          ทีมงานทุกคนของชุมชนจะไม่ได้รับเงินจากการบริจาคใดๆ
                          ทั้งสิ้น
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {!user ? (
                    <button
                      onClick={login}
                      type="button"
                      className="btn btn-custom-login d-flex gap-2"
                      style={{ width: 305 }}
                    >
                      <i className="fab fa-discord fa-lg" />
                      <span>Login With Discord First To Donate</span>
                    </button>
                  ) : (
                    <div className="blog-widget">
                      <h4 className="widget-title">Donate</h4>
                      {stage === "qr-code" ? (
                        <div className="sidebar-newsletter text-center">
                          <div className="d-flex flex-column gap-3 align-items-center">
                            <img
                              className="w-75"
                              src={qrCodeImage}
                              alt="qr-code"
                            />
                            <button
                              onClick={() => {
                                setStage("");
                                setIsCheckQRCodePayment(false);
                                setQRCodeRef("");
                              }}
                              type="button"
                              className="btn-warning w-75 border-0 text-white py-2"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      ) : stage === "true-wallet" ? (
                        <div className="sidebar-newsletter text-center">
                          <div className="d-flex flex-column gap-3 align-items-center">
                            <iframe height={400} srcDoc={rawHTML}></iframe>
                            <button
                              onClick={() => {
                                setStage("");
                                setIsCheckTrueWalletPayment(false);
                                setTrueWalletRef("");
                              }}
                              type="button"
                              className="btn-warning w-75 border-0 text-white py-2"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="sidebar-newsletter text-center">
                          <div className="sn-thumb mb-30">
                            <img
                              src="assets/img/icon/counter_icon03.png"
                              alt=""
                            />
                          </div>
                          <div className="sn-content">
                            <p>Donate By {activeDonateType}</p>
                            <div className="d-flex justify-content-center align-items-center gap-5 mb-3">
                              <div className="d-flex gap-1">
                                <input
                                  style={{ width: 20, height: 20 }}
                                  id="qr-code"
                                  type="radio"
                                  name="donateType"
                                  checked={activeDonateType === "QR CODE"}
                                  value="QR CODE"
                                  onClick={(e) =>
                                    setActiveDonateType(e.target.value)
                                  }
                                />
                                <label for="qr-code">QR Code</label>
                              </div>
                              <div className="d-flex gap-1">
                                <input
                                  style={{ width: 20, height: 20 }}
                                  id="true-wallet"
                                  type="radio"
                                  name="donateType"
                                  checked={
                                    activeDonateType === "TRUE MONEY WALLET"
                                  }
                                  value="TRUE MONEY WALLET"
                                  onClick={(e) =>
                                    setActiveDonateType(e.target.value)
                                  }
                                />
                                <label for="true-wallet">
                                  True Money Wallet
                                </label>
                              </div>
                            </div>
                            <form onSubmit={startDonate}>
                              <input
                                onChange={(e) => setAmount(e.target.value)}
                                type="number"
                                placeholder="Enter Amount"
                                value={amount}
                                min={1}
                              />
                              {activeDonateType === "TRUE MONEY WALLET" && (
                                <input
                                  onChange={(e) => setPhone(e.target.value)}
                                  type="tel"
                                  placeholder="Enter Phone"
                                  value={phone}
                                  min={1}
                                />
                              )}
                              <button className="btn d-flex justify-content-center">
                                Donate Now !!
                              </button>
                            </form>
                            <p className="last-text">Secured by GB PRIMEPAY</p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Donate;
