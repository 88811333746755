import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import * as rdd from "react-device-detect";

function Setting() {
  const [isHideHomepageModal, setIsHideHomepageModal] = useState(
    localStorage.getItem("is_hide_main_modal")
  );
  const [isHideSyncGameUsernameModal, setIsHideSyncGameUsernameModal] =
    useState(localStorage.getItem("is_hide_sync_username_modal"));

  const saveSetting = () => {
    localStorage.setItem("is_hide_main_modal", isHideHomepageModal);
    localStorage.setItem(
      "is_hide_sync_username_modal",
      isHideSyncGameUsernameModal
    );

    toast.success("Saved !!");
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <HeaderOne />
      <main>
        <div className="container custom-container mb-200">
          <div
            style={{ paddingLeft: rdd.isMobile ? 50 : 250, paddingRight: 150 }}
            className="row"
          >
            <div className="col-md-5 mt-150">
              <h1>Website Setting</h1>
              <div className="row">
                <div className="col-12 mt-30">
                  <div className="d-flex flex-column">
                    <p>Homepage Modal</p>
                    <div className="d-flex align-items-center">
                      <div className="d-flex gap-2">
                        <input
                          id="show-homepage-modal"
                          type="radio"
                          name="homepage-modal"
                          value="false"
                          checked={isHideHomepageModal === "false"}
                          onClick={(e) =>
                            setIsHideHomepageModal(e.target.value)
                          }
                        />
                        <label htmlFor="show-homepage-modal">Show</label>
                      </div>
                      <div style={{ marginLeft: 30 }} className="d-flex gap-2">
                        <input
                          id="hide-homepage-modal"
                          type="radio"
                          name="homepage-modal"
                          value="true"
                          checked={isHideHomepageModal === "true"}
                          onClick={(e) =>
                            setIsHideHomepageModal(e.target.value)
                          }
                        />
                        <label htmlFor="hide-homepage-modal">Hide</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-30">
                  <div className="d-flex flex-column">
                    <p>Sync Game Username Modal</p>
                    <div className="d-flex align-items-center">
                      <div className="d-flex gap-2">
                        <input
                          id="show-sync-game-username-modal"
                          type="radio"
                          name="sync-game-username-modal"
                          value="false"
                          checked={isHideSyncGameUsernameModal === "false"}
                          onClick={(e) =>
                            setIsHideSyncGameUsernameModal(e.target.value)
                          }
                        />
                        <label htmlFor="show-sync-game-username-modal">
                          Show
                        </label>
                      </div>
                      <div style={{ marginLeft: 30 }} className="d-flex gap-2">
                        <input
                          id="hide-sync-game-username-modal"
                          type="radio"
                          name="sync-game-username-modal"
                          value="true"
                          checked={isHideSyncGameUsernameModal === "true"}
                          onClick={(e) =>
                            setIsHideSyncGameUsernameModal(e.target.value)
                          }
                        />
                        <label htmlFor="hide-sync-game-username-modal">
                          Hide
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-50">
                  <button onClick={saveSetting} type="button" className="btn">
                    Save Setting
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="d-none d-md-flex">
                <img
                  style={{ objectFit: "cover", borderRadius: 10 }}
                  className="w-100 h-100 rounded"
                  src="/img/kinesis_1.png"
                  alt="not-select"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Setting;
