import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

function TopBlogs() {
  const [isLoading, setIsLoading] = useState(true);
  const [blog1, setBlog1] = useState(null);
  const [blog2, setBlog2] = useState(null);
  const [blog3, setBlog3] = useState(null);
  const [blog4, setBlog4] = useState(null);
  const [blog5, setBlog5] = useState(null);

  useEffect(() => {
    getTopBlogs();
  }, []);

  const getTopBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/settings`
      );

      setBlog1(response.data.data.blog1);
      setBlog2(response.data.data.blog2);
      setBlog3(response.data.data.blog3);
      setBlog4(response.data.data.blog4);
      setBlog5(response.data.data.blog5);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {!isLoading && (
        <div className="d-flex flex-column align-items-center pt-120 gap-3">
          {blog1 && (
            <Link
              to={`/blog/${blog1.id}`}
              style={{ height: 500, width: "60%" }}
              className="bg-secondary rounded"
            >
              <img
                className="w-100 h-100 object-fit-cover rounded"
                src={blog1.image_url}
                alt="blog-1"
              />
            </Link>
          )}
          <div style={{ width: "60%" }} className="d-flex gap-3">
            {blog2 && (
              <Link
                to={`/blog/${blog2.id}`}
                style={{ height: 150, width: "25%" }}
                className="bg-secondary rounded"
              >
                <img
                  className="w-100 h-100 object-fit-cover rounded"
                  src={blog2.image_url}
                  alt="blog-2"
                />
              </Link>
            )}
            {blog3 && (
              <Link
                to={`/blog/${blog3.id}`}
                style={{ height: 150, width: "25%" }}
                className="bg-secondary rounded"
              >
                <img
                  className="w-100 h-100 object-fit-cover rounded"
                  src={blog3.image_url}
                  alt="blog-3"
                />
              </Link>
            )}
            {blog4 && (
              <Link
                to={`/blog/${blog4.id}`}
                style={{ height: 150, width: "25%" }}
                className="bg-secondary rounded"
              >
                <img
                  className="w-100 h-100 object-fit-cover rounded"
                  src={blog4.image_url}
                  alt="blog-4"
                />
              </Link>
            )}
            {blog5 && (
              <Link
                to={`/blog/${blog5.id}`}
                style={{ height: 150, width: "25%" }}
                className="bg-secondary rounded"
              >
                <img
                  className="w-100 h-100 object-fit-cover rounded"
                  src={blog5.image_url}
                  alt="blog-5"
                />
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default TopBlogs;
