import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import axios from "axios";
import moment from "moment";

function Tournament() {
  const [isLoading, setIsLoading] = useState(true);
  const [tournaments, setTournaments] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));

  useEffect(() => {
    getTournaments();
  }, [isLoading]);

  const searchTournaments = (e) => {
    e.preventDefault();
    if (search) {
      setSearchParams({ search: search });
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
    setIsLoading(true);
  };

  const getTournaments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/tournaments`,
        {
          params: {
            search: search,
          },
        }
      );

      setTournaments(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderOne />
      <main>
        <section className="blog-area black-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-sidebar mb-4">
                  <div className="blog-widget">
                    <h4 className="widget-title">Searching</h4>
                    <div className="sidebar-search">
                      <form onSubmit={(e) => searchTournaments(e)}>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                          type="text"
                          placeholder="Search..."
                        />
                        <button type="submit">
                          <i className="fas fa-search" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="blog-sidebar">
                  <div className="blog-widget">
                    <h4 className="widget-title">Tournament Lists</h4>
                    <div className="rc-post-list">
                      <ul>
                        {!isLoading &&
                          tournaments.map((tournament) => {
                            return (
                              <div className="d-md-flex align-items-center justify-content-between mb-4">
                                <Link
                                  className="w-100"
                                  key={tournament.id}
                                  to={`/tournaments/${tournament.id}`}
                                >
                                  <li className="mb-4">
                                    <div className="rc-post-thumb">
                                      <img src={tournament.image_url} alt="" />
                                    </div>
                                    <div className="rc-post-content">
                                      <h5 className="title">
                                        {tournament.tournament_name}
                                      </h5>
                                      <span className="date">
                                        <i className="far fa-clock" />
                                        {moment(tournament.start_date).format(
                                          "DD MMMM YYYY"
                                        )}
                                        <span> - </span>
                                        {moment(tournament.end_date).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                </Link>
                                <Link
                                  className="btn-primary rounded px-4 py-2"
                                  to={`/tournaments/${tournament.id}/teams`}
                                >
                                  Teams
                                </Link>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Tournament;
