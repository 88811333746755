import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import axios from "axios";
import moment from "moment/moment";
import * as rdd from "react-device-detect";

function DonateHistory() {
  const [transactions, setTransactions] = useState([]);
  const [totalDonate, setTotalDonate] = useState(0);

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/donate`,
        { headers }
      );
      console.log(response);
      const data = response.data.data;

      let total = 0;

      for (let i = 0; i < data.length; i++) {
        total += data[i].amount;
      }

      setTransactions(data);
      setTotalDonate(total);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderOne />
      <main>
        <section className="breadcrumb-area breadcrumb-bg py-5">
          <div className="breadcrumb-overflow">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-12">
                  <div className="blog-widget h-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="widget-title">Donate History</h4>
                      <h4 className="widget-title">
                        Total Donate: {totalDonate}
                      </h4>
                    </div>
                    <div
                      style={{ height: 500, overflowY: "scroll" }}
                      className="sidebar-newsletter text-left rounded"
                    >
                      <div className="sn-thumb">
                        <div
                          style={{ width: rdd.isMobile ? "200%" : "content" }}
                          className="row bg-secondary p-4 rounded mb-3"
                        >
                          <div className="col-1">
                            <h5 className="my-auto">#</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Amount</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Type</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Phone</h5>
                          </div>
                          <div className="col-3 d-flex justify-content-center">
                            <h5 className="my-auto">Ref</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-end">
                            <h5 className="my-auto">Date</h5>
                          </div>
                        </div>
                        {transactions.map((transction, index) => {
                          return (
                            <div
                              style={{
                                width: rdd.isMobile ? "200%" : "content",
                              }}
                              className="row bg-secondary p-4 rounded mb-3"
                            >
                              <div className="col-1">
                                <h5 className="my-auto">{index + 1}</h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">{transction.amount}</h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">{transction.type}</h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my">
                                  {transction.customer_phone
                                    ? transction.customer_phone
                                    : "-"}
                                </h5>
                              </div>
                              <div className="col-3 d-flex justify-content-center">
                                <h5 className="my-auto">
                                  {transction.reference_no}
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-end">
                                <h5 className="my-auto">
                                  {moment(transction.created_at).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </h5>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default DonateHistory;
